import React from "react"
import brand1 from "./../images/amazon.svg"
import brand2 from "./../images/alle-processing.svg"
import brand3 from "./../images/illustrations_lg-clients.svg"
import Fade from 'react-reveal/Fade';

const Brands = ({ data }) => {
  return (
    <div className="bg-black">
      <div className="max-w-twelve mx-auto justify-between flex flex-col ">
        <Fade duration={1300}>
        <p
          className="text-base text-white md:text-base2 lg:text-lg md:ml-32 ml-20 mr-12"
          style={{ maxWidth: "690px" }}
        >
          Cider Consulting is a team of creative people working with you to
          build your digital products.
        </p>
        </Fade>
        <Fade duration={1300}>
        <div className="flex mt-50 flex-col md:flex-row justify-center mb-25">
          <div className="flex justify-center">
            <div className="mx-10">
              <img
                src={brand2}
                className="lg:w-24 w-16"
                style={{ margin: 0 }}
                alt="brand logo"
              />
            </div>
            <div className="mx-10">
              <img
                src={brand2}
                className="lg:w-24 w-16"
                style={{ margin: 0, color: "" }}
                alt="brand logo"
              />
            </div>
            <div className="mx-10">
              <img
                src={brand2}
                className="lg:w-24 w-16"
                style={{ margin: 0, color: "white" }}
                alt="brand logo"
              />
            </div>
          </div>
          <div className="flex justify-center mt-8 md:mt-0">
            <div className="mx-10">
              <img
                src={brand2}
                className="lg:w-24 w-16"
                style={{ margin: 0, color: "white" }}
                alt="brand logo"
              />
            </div>
            <div className="mx-10">
              <img
                src={brand2}
                className="lg:w-24 w-16"
                style={{ margin: 0, color: "white" }}
                alt="brand logo"
              />
            </div>
          </div>
        </div>
      </Fade>
      </div>
    </div>
  )
}

export default Brands
