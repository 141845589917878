import React, { useState, useEffect } from "react"
import { window, document } from "browser-monads"
import Bubbles from "../components/Bubbles"

const Hero = () => {
  var TxtRotate = function (el, toRotate, period) {
    this.toRotate = toRotate
    this.el = el
    this.loopNum = 0
    this.period = parseInt(period, 10) || 2000
    this.txt = ""
    this.tick()
    this.isDeleting = false
  }
  const [active, setActive] = useState(false)

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (!active) {
      var elements = document.getElementsByClassName("txt-rotate")
      for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute("data-rotate")
        var period = elements[i].getAttribute("data-period")
        if (toRotate) {
          new TxtRotate(elements[i], JSON.parse(toRotate), period)
        }
      }
      // INJECT CSS
      var css = document.createElement("style")
      css.type = "text/css"
      css.innerHTML =
        ".txt-rotate > .wrap { border-right: 0.02em solid #d6a30d; padding-right: 8px } @media only screen and (max-width: 768px) { .text-rotate .wrap { padding-right: 30px; } } "
      document.body.appendChild(css)
      setActive(true)
    }
  })

  TxtRotate.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length
    var fullTxt = this.toRotate[i]

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1)
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1)
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>"

    var that = this
    var delta = 300 - Math.random() * 100

    if (this.isDeleting) {
      delta /= 2
    }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period
      this.isDeleting = true
    } else if (this.isDeleting && this.txt === "") {
      this.isDeleting = false
      this.loopNum++
      delta = 500
    }

    setTimeout(function () {
      that.tick()
    }, delta)
  }

  return (
    <>
      <div className="w-full overflow-hidden pt-25 bg-black" id="top">
  
        <Bubbles />
        <div className="bg-black lg:p-0 ">
          <div className="mx-auto h-screen text-white flex justify-center flex-col px-8 md:px-16 lg:px-30 lg:w-fourteen md:w-ten lg:max-w-none ">
            <h2
              className="font-bold font-sofia-bold text-40 z-20 sm:text-60 md:text-90 lg:text-3xl md:w-820 lg:w-1100 sm:w-640 xs:w-320 w-40 -mt-2 lg:ml-2"
              style={{ letterSpacing: "-2px" }}
            >
              We make building technology
            </h2>
            <h1
              className="lg:text-4xl md:text-150 sm:text-120 text-50 txt-rotate mt-10 headline relative z-0 text-black"
              data-period="2000"
              data-rotate='[ "Simple.", "Painless.", "Smooth.", "Pretty.", "Fun!" ]'
              style={{ fontFamily: "Knockout" }}
            >
              Painless
            </h1>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero
