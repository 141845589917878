import React from "react"
import ProfileCard from "./ProfileCard"
import Fade from "react-reveal/Fade"

const AboutLower = ({ rogelImage, isaacImage }) => {
  return (
    <div className="overflow-hidden">
      <div className="lg:w-fourteen md:w-ten lg:pb-40 sm:px-16 lg:px-32 px-5 mx-auto pb-15 sm:pb-20 md:px-16">
        <div className="flex flex-col sm:flex-row">
          <Fade duration={1300}>
            <div className="md:mr-20 sm:mr-15">
              <ProfileCard
                title="Isaac Abraham — CEO"
                text="“This should be a quote of what you beleve in regarding what cider does or something personal form life”"
                image={isaacImage}
              />
            </div>
          </Fade>
          <Fade duration={1300}>
            <div className="">
              <ProfileCard
                title="Rogel Sokolin-Maimon — COO"
                text="“This should be a quote of what you beleve in regarding what cider does or something personal form life”"
                image={rogelImage}
              />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default AboutLower
