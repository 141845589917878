import React, { useState, useRef, useEffect } from "react"
import ProjectCard from "./ProjectCard"
import Fade from "react-reveal/Fade"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import useCurrentWidth from "../hooks/useCurrentWidth"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const Clients = ({ image }) => {
  let width = useCurrentWidth()

  let tl1 = gsap.timeline()

  const target0 = useRef(null)

  useEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width: 759px)": function () {
        tl1.kill();
        tl1.from(target0.current, {
          scrollTrigger: {
            trigger: "#clientsHeading",
            start: "top 100%",
            end: "+=80%",
            scrub: true,
            markers: false,
          },
          xPercent: 50,
          ease: "none",
        })
      },
    })
    console.log("refresh occured")
  }, [width])

  const settings = {
    className: "mt-20 -mr-100 ",
    lazyLoad: false,
    infinite: true,
    centerPadding: "-100px",
    centerMode: true,
    slidesToScroll: 1,
    slidesToShow: 3,
    speed: 700,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          className: "mt-20 -mr-100  center",
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "-0px",
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          className: "mt-15 -mr-100 center",
          centerMode: false,
          centerPadding: "-200px",
          slidesToScroll: 1,
          infinite: true,
        },
      },
      // {
      //   breakpoint: 768,
      //   settings: {
      //     className: "mt-12 mr-20 center",
      //     centerMode: true,
      //     centerPadding: "-20px",
      //     slidesToShow: 2,
      //     slidesToScroll: 1,
      //     initialSlide: 1,
      //   },
      // },
      {
        breakpoint: 550,
        settings: {
          className: "mt-10 -mx-5",
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }

  return (
    <div className="overflow-x-hidden" id="clients">
      <div
        className="lg:w-fourteen lg:pt-30 md:w-ten lg:px-32 md:px-16 md:pt-20  sm:px-16 sm:pt-20 px-5 pt-15 mx-auto"
        ref={target0}
        id="target0"
      >
        <h2
          className="lg:text-160 md:text-140 sm:text-100 text-60 lg:mb-25 md:mb-20 sm:mb-15 mb-8 subline font-knockout text-sand -mr-100"
          // style={{ transform: `translateX(${translate}px)` }}
          id="clientsHeading"
        >
          Clients
        </h2>
      </div>
      <div className="lg:w-fourteen lg:pb-40 md:w-ten lg:px-32 md:px-16 md:pb-25 sm:px-16 sm:pb-20 px-5 pb-15 mx-auto">
        <div className="flex sm:flex-row flex-col">
          <Fade duration={1300}>
            <div className="lg:w-1/2 md:w-3/6 sm:w-3/6">
              <h3 className="lg:text-2xl md:text-1xl sm:text-30 text-25 font-sofia-bold mb-3">
                Taking businesses internal management to the next level
              </h3>
              <p className="text-read2 lg:text-lg md:text-base2 text-18l mb-12">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent ultrices, eros eget laoreet commodo, erat nisl
                elementum dui, sed volutpat diam felis vestibulum mi.
              </p>

              <div className="sm:mb-0 mb-15">
                <p
                  className="mb-5 text-18"
                  style={{ letterSpacing: "1.35px", opacity: "0.5" }}
                >
                  Expertise fields
                </p>
                {/* TAGS */}
                <div className="text-sm">
                  <span className="bg-offwhite pb-2 px-3 leading-relaxed pt-3 mr-3">
                    HEALTH
                  </span>
                  <span className="bg-offwhite pb-2 px-3 leading-relaxed pt-3 m-3">
                    INSURANCE
                  </span>
                  <span className="bg-offwhite pb-2 px-3 leading-relaxed pt-3 mx-3 sm:inline hidden">
                    MANUFACTURER
                  </span>
                  <div className="mt-8">
                    <span className="bg-offwhite pb-2 px-3 leading-relaxed pt-3">
                      MANUFACTURER
                    </span>
                    <span className="bg-offwhite pb-2 px-3 leading-relaxed pt-3 ml-6 sm:hidden">
                      MANUFACTURER
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Fade>

          <div className="ml-5 sm:mt-6 md:mt-0 flex-1">
            <img src={image} className=" sm:ml-10 lg:ml-0" alt="Projects" />
          </div>
        </div>
        <div className="justify-start mb-16 sm:mb-20 md:mb-25 lg:mb-35 sm:-mr-25 md:mt-20 sm:mt-15  xs:-mr-100">
          <Slider
            {...settings}
            ref={slider => (slider = slider && slider["innerSlider"])}
          >
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
          </Slider>
        </div>
      </div>
    </div>
  )
}

// class Clients extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = { translate: 0 }
//     this.handleScroll = this.handleScroll.bind(this)
//   }
//   handleScroll() {
//     let element = document.getElementById("clientsHeading")
//     let rect = element.getBoundingClientRect()
//     let scrollPercent = (rect.y / window.innerHeight) * 100
//     let xOffSet = (scrollPercent - 10) * 4
//     if (xOffSet < 0) {
//       xOffSet = 0
//     } else if (scrollPercent > 120) {
//       xOffSet = 120 * 4
//     }
//     this.setState({ translate: xOffSet })
//   }
//   componentDidMount() {
//     window.addEventListener("scroll", this.handleScroll)
//   }
//   render() {
//     const { gatsbyImage, image } = this.props
//     const settings = {
//       className: "mt-20 -mr-100 ",
//       lazyLoad: true,
//       infinite: true,
//       centerPadding: "-100px",
//       centerMode: true,
//       slidesToScroll: 1,
//       slidesToShow: 3,
//       speed: 700,
//       responsive: [
//         {
//           breakpoint: 1440,
//           settings: {
//             className: "mt-20 -mr-100  center",
//             slidesToShow: 3,
//             centerMode: true,
//             centerPadding: "-0px",
//             slidesToScroll: 1,
//             infinite: true,
//           },
//         },
//         {
//           breakpoint: 1024,
//           settings: {
//             slidesToShow: 3,
//             className: "mt-15 -mr-100 center",
//             centerMode: false,
//             centerPadding: "-200px",
//             slidesToScroll: 1,
//             infinite: true,
//           },
//         },
//         {
//           breakpoint: 768,
//           settings: {
//             className: "mt-12 mr-20 center",
//             centerMode: true,
//             centerPadding: "-20px",
//             slidesToShow: 2,
//             slidesToScroll: 1,
//             initialSlide: 1,
//           },
//         },
//         {
//           breakpoint: 550,
//           settings: {
//             className: "mt-10 -mx-5",
//             centerMode: false,
//             slidesToShow: 1,
//             slidesToScroll: 1,
//             initialSlide: 1,
//           },
//         },
//       ],
//     }

//     return (
//       <div className="overflow-x-hidden" id="clients">
//         <div className="lg:w-fourteen lg:pb-40 lg:pt-30 md:w-ten lg:px-32 md:px-16 md:pt-20 md:pb-25 sm:px-16 sm:py-20 px-5 py-15 mx-auto">
//           <h2
//             className="lg:text-160 md:text-140 sm:text-100 text-60 lg:mb-25 md:mb-20 sm:mb-15 mb-8 subline font-knockout text-sand -mr-100"
//             style={{ transform: `translateX(${this.state.translate}px)` }}
//             id="clientsHeading"
//           >
//             Clients
//           </h2>
//           <div className="flex sm:flex-row flex-col">
//             <Fade duration={1300}>
//               <div className="lg:w-1/2 md:w-3/6 sm:w-3/6">
//                 <h3 className="lg:text-2xl md:text-1xl sm:text-30 text-25 font-sofia-bold mb-3">
//                   Taking businesses internal management to the next level
//                 </h3>
//                 <p className="text-read2 lg:text-lg md:text-base2 text-18l mb-12">
//                   Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//                   Praesent ultrices, eros eget laoreet commodo, erat nisl
//                   elementum dui, sed volutpat diam felis vestibulum mi.
//                 </p>

//                 <div className="sm:mb-0 mb-15">
//                   <p
//                     className="mb-5 text-18"
//                     style={{ letterSpacing: "1.35px", opacity: "0.5" }}
//                   >
//                     Expertise fields
//                   </p>
//                   {/* TAGS */}
//                   <div className="text-sm">
//                     <span className="bg-offwhite pb-2 px-3 leading-relaxed pt-3 mr-3">
//                       HEALTH
//                     </span>
//                     <span className="bg-offwhite pb-2 px-3 leading-relaxed pt-3 m-3">
//                       INSURANCE
//                     </span>
//                     <span className="bg-offwhite pb-2 px-3 leading-relaxed pt-3 mx-3 sm:inline hidden">
//                       MANUFACTURER
//                     </span>
//                     <div className="mt-8">
//                       <span className="bg-offwhite pb-2 px-3 leading-relaxed pt-3">
//                         MANUFACTURER
//                       </span>
//                       <span className="bg-offwhite pb-2 px-3 leading-relaxed pt-3 ml-6 sm:hidden">
//                         MANUFACTURER
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </Fade>

//             <div className="ml-5 sm:mt-6 md:mt-0 flex-1">
//               {/* <Img
//                 fluid={gatsbyImage}
//                 className=" sm:ml-10 lg:ml-0"
//                 alt="Projects"
//               /> */}
//               <img
//                 src={image}
//                 className=" sm:ml-10 lg:ml-0"
//                 alt="Projects"
//               />
//             </div>
//           </div>
//           <div className="justify-start mb-16 sm:mb-20 md:mb-25 lg:mb-35 sm:-mr-25 md:mt-20 sm:mt-15  xs:-mr-100">
//             <Slider
//               {...settings}
//               ref={slider => (this.slider = slider && slider["innerSlider"])}
//             >
//               <ProjectCard />
//               <ProjectCard />
//               <ProjectCard />
//               <ProjectCard />
//               <ProjectCard />
//             </Slider>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

export default Clients
