import React from "react"
import Image from "gatsby-image"

const AboutCard = ({ image, title, text }) => {
  return (
    <div className="flex sm:flex-row flex-col-reverse justify-between py-10 sm:px-10">
      <div className="flex-1 flex flex-col lg:mr-20 md:mr-15 sm:mr-8">
        <h4 className="font-sofia-bold mx-5 sm:mx-0 lg:text-2xl md:text-1xl sm:text-30 text-24 mb-5">
          {title}
        </h4>
        <p className="text-read2 px-5 sm:px-0 lg:text-lg md:text-base2 text-18l mb-10 ">
          {text}
        </p>
      </div>
      <div className="flex-1">
      <Image fluid={image} alt="Case study" className="mb-4 sm:mb-0" />
      </div>
    </div>
  )
}

export default AboutCard

