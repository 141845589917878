import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import AboutCard from "./AboutCard"
import useCurrentWidth from "../hooks/useCurrentWidth"

gsap.registerPlugin(ScrollTrigger)

const card1 = {
  title: "Making everything clear and simple for you 1",
  text:
    "Cider Consulting is your partner throughout the development process, guiding you through every phase to deliver on time, on spec, and on budget",
}
const card2 = {
  title: "Making everything easy and quick for you 2",
  text:
    "Cider Consulting is your solution to process development, and will guide you through every important phase of your project",
}

const ScrollCards = ({ image }) => {
  let tl0 = gsap.timeline()
  let tl1 = gsap.timeline()
  let tl2 = gsap.timeline()
  let tl3 = gsap.timeline()

  let width = useCurrentWidth()

  console.log(width)

  let cardHeight

  let travel

  const target0 = useRef(null)
  const target1 = useRef(null)

  if (width >= 1440) {
    travel = 390
  } else if (width >= 810) {
    travel = 390
  } else if (width >= 760) {
    travel = 410
  } else if (width >= 660) {
    travel = 755
  } else if (width >= 600) {
    travel = 710
  } else if (width >= 550) {
    travel = 700
  } else if (width >= 450) {
    travel = 670
  } else if (width < 450) {
    travel = 650
  }

  useEffect(() => {
    tl0.kill();
    tl0.from(target0.current, {
      scrollTrigger: {
        trigger: "#headingAbout",
        start: "top 100%",
        end: "+=75%",
        scrub: true,
        markers: false,
      },
      xPercent: 40,
      ease: "none",
    })
  }, [width])

  useEffect(() => {
    tl1.to(
      "#box",
      {
        scrollTrigger: {
          trigger: ".scrollContainer",
          start: "top 0",
          end: `+=${travel}`,
          markers: false,
          scrub: true,
          pin: true,
          pinSpacing: false,
        },
        duration: 20,
        ease: "power3.in",
        scaleX: 0.85,
        scaleY: 0.85,
        autoAlpha: 0,
      },
      [width]
    )

    tl2.to(
      "#box2",
      {
        scrollTrigger: {
          trigger: ".scrollContainer",
          start: "top 0",
          end: `+=${travel}`,
          markers: false,
          scrub: true,
          pinSpacing: false,
        },
        duration: 20,
        ease: "none",
        y: travel * -1,
      },
      [width]
    )

    tl3.to(
      "#box2",
      {
        scrollTrigger: {
          trigger: ".scrollContainer",
          start: "top 0",
          end: "+=50",
          markers: false,
          scrub: true,
          pinSpacing: false,
        },

        ease: "none",
        opacity: 1,
      },
      [width]
    )
  })
  useEffect(() => {
    cardHeight = target1.current.offsetHeight
  }, [width])

  return (
    <div className="overflow-hidden mb-10 sm:mb-20 md:mb-32 lg:mb-40 mt-32 sm:mt-10 lg:mt-0">
      <div style={{ width: "100%" }} className="scrollContainer">
        <div
          className="lg:w-fourteen lg:pt-30 md:w-ten lg:px-32 md:px-16 md:pt-20 sm:px-16 sm:pt-20 px-5 pt-15 mx-auto"
          id="about"
          ref={target0}
        >
          <h2
            className={`lg:text-160 md:text-140 sm:text-100 text-60 sm:mb-10 mb-8 md:mb-5 sm:ml-0 subline font-knockout text-sand`}
            id="headingAbout"
          >
            About
          </h2>
        </div>
        <div className="w-full overflow-hidden" id="box" ref={target1}>
          <div className="lg:w-fourteen md:w-ten mx-auto lg:px-30 md:px-5">
            <AboutCard image={image} title={card1.title} text={card1.text} />
          </div>
        </div>

        <div
          className=" w-full overflow-hidden opacity-0 bg-white"
          id="box2"
          ref={target1}
          style={{ boxShadow: "0 -3px 6px -6px black" }}
        >
          <div className="lg:w-fourteen md:w-ten mx-auto lg:px-30 md:px-5">
            <AboutCard image={image} title={card2.title} text={card2.text} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScrollCards
