import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Link from "gatsby-link"

const ProjectCard = () => {
  const data = useStaticQuery(graphql`
    query ProjectQuery {
      projectImage: file(name: { eq: "case_study_sample" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="lg:w-xs xs:w-350 flex flex-col mt-20 sm:mt-0 md:-mr-0 sm:-mr-20 sm:-ml-1 pl-1 -mr-0 sm:mr-5 mx-10">
      <Img
        fluid={data.projectImage.childImageSharp.fluid}
        alt="Case study w-xs"
      />
      <div className="flex flex-col">
        <h4
          className="font-sofia-bold lg:text-2xl md:text-1xl text-25 mt-7 mb-2"
          style={{ letterSpacing: "-1px" }}
        >
          This is the project title
        </h4>
        <p
          className="lg:text-lg md:text-base2 sm:text-18l text-read2 mb-10"
          style={{ letterSpacing: "-0.56px" }}
        >
          This is the project description, something that will summarize what we
          did on this project and how it helped the business and the product
          users
        </p>
        <div>
          <Link to="/case-study">
            <button className="text-18 rounded-full border border-black leading-relaxed py-1 px-5">
              Read Case Study
              <span className="arrow">
                <span style={{ position: "absolute", right: "15px" }}>
                  {" "}
                  &nbsp;➞
                </span>
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ProjectCard
