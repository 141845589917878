import React from "react"
import Link from "gatsby-link"
import Fade from "react-reveal/Fade"
import Image from "gatsby-image"
import iconCareers from "../images/cider-website-illustrations_lg-careers.svg"

class Careers extends React.Component {
  constructor(props) {
    super(props)
    this.state = { marginLeft: "0px" }
    this.handleScroll = this.handleScroll.bind(this)
  }
  handleScroll() {
    let element = document.getElementById("careersServices")
    let rect = element.getBoundingClientRect()
    let scrollPercent = (rect.y / window.innerHeight) * 100
    let xOffSet = (scrollPercent - 10) * 4
    if (xOffSet < 0) {
      xOffSet = 0
    } else if (scrollPercent > 120) {
      xOffSet = 120 * 4
    }
    xOffSet = xOffSet + "px"
    this.setState({ marginLeft: xOffSet })
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  render() {
    const { title, text, button, gatsbyImage } = this.props
    return (
      <div className="overflow-hidden">
        <div className="lg:w-fourteen lg:pb-40 lg:pt-30 md:w-ten lg:px-32 md:px-16 md:pt-20 md:pb-25 sm:px-16 sm:py-20 px-5 py-15 mx-auto">
          <h2
            className="heading mb-15 subline font-knockout text-sand -mr-100"
            style={this.state}
            id="careersServices"
          >
            Careers
          </h2>
          <Fade duration={1300}>
            <div className="flex sm:flex-row flex-col">
              <div className="flex-1">
                <h3 className="subheading mb-6 w-full xs:w-5/6 md:w-full">{title}</h3>
                <p className="text mb-12 w-full xs:w-5/6 sm:w-100 md:w-full">{text}</p>
              </div>
              <div className="flex-1">
                {/* <Image fluid={gatsbyImage} alt="Projects" className="ml-10" /> */}
                <img src={iconCareers} alt="Projects" className="ml-10 lg:p-10" />
              </div>
            </div>
            <Link to="/careers">
              <button className="bg-yellow text-black h-10 px-8 py-2 rounded-button leading-relaxed sm:mt-0 mt-15">
                {button}
                <span className="arrow">
                  <span style={{ position: "absolute", right: "15px" }}>
                    {" "}
                    &nbsp;➞
                  </span>
                </span>
              </button>
            </Link>
          </Fade>
        </div>
      </div>
    )
  }
}

export default Careers
