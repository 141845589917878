import React from "react"
import Link from "gatsby-link"
import Fade from "react-reveal/Fade"
import { window, document } from "browser-monads"
import "../styles/scroll.css"


class Trio extends React.Component {
  constructor(props) {
    super(props)
    this.state = { transform: "0px" }
    this.handleScroll = this.handleScroll.bind(this)
  }
  handleScroll() {
    let element = document.getElementById("headingServices")
    let rect = element.getBoundingClientRect()
    let scrollPercent = (rect.y / window.innerHeight) * 100
    let xOffSet = (scrollPercent - 10) * 4
    if (xOffSet < 0) {
      xOffSet = 0
    } else if (scrollPercent > 120) {
      xOffSet = 120 * 4
    }
    this.setState({ transform: `translateX(${xOffSet}px)` })
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  render() {
    const { icon1, icon2, icon3 } = this.props
    const { title1, title2, title3 } = this.props
    const { text1, text2, text3 } = this.props

    return (
      <div
        className="overflow-hidden"
        id="services"
      >
        <div className="lg:w-fourteen lg:pb-40 lg:pt-30 md:w-ten lg:px-32 md:px-16 md:pt-20 md:pb-25 sm:px-16 sm:py-20 px-5 py-15 mx-auto">
          <h2
            className="lg:text-160 md:text-140 lg:mb-20 md:12 mb-8 sm:text-100 text-50 subline font-knockout -mr-100"
            style={this.state}
            id="headingServices"
          >
            Services
          </h2>
          <Fade duration={1300}>
            <div className="flex flex-col">
              <h3 className="lg:text-2xl md:text-1xl sm:text-30 text-24 md:mb-6 mb-4 w-5/6 font-sofia-bold" style={{ fontWeight: "600" }}>
                From planning &#038; strategy, high level development, and
                testing.
              </h3>
              <p className="lg:text-lg sm:text-base2 text-base text-read2 lg:mb-40 md:mb-35 mb-20 w-5/6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent ultrices, eros eget laoreet commodo, erat nisl
                elementum dui, sed volutpat diam felis vestibulum mi. erat nisl
                elementum dui, sed volutpat diam felis vestibulum mi.
              </p>
            </div>
          </Fade>
          <Fade duration={1300}>
            <div className="sm:flex sm:w-full sm:justify-between grid grid-cols-3 gap-20 overflow-x-scroll sm:overflow-x-hidden hs">
              <div className="flex flex-col md:w-4/6 lg:w-2/6 font-hairline sm:items-start sm:mx-0 mx-2">
                <div className="mb-6">
                  <img
                    src={icon1}
                    alt={title1}
                    className="md:h-45 lg:h-64 sm:h-35 h-40"
                  />
                </div>
                <h3 className="xl:text-30 font-sofia-med md:text-lg text-base2 font-bold md:mb-5 mb-3 w-48">
                  {title1}
                </h3>
                <p className="sm:text-lg text-read2 text-18 w-5/6">{text1}</p>
              </div>
              <div className="flex flex-col md:w-4/6 lg:w-2/6 font-hairline sm:items-start">
                <div className="mb-6">
                  <img
                    src={icon2}
                    className="md:h-45 lg:h-64 sm:h-35 h-40 sm:mt-0"
                    alt={title2}
                  />
                </div>
                <h3 className="xl:text-xl font-sofia-med md:text-lg text-base2 font-bold md:mb-5 mb-3 w-48">
                  {title2}
                </h3>
                <p className="sm:text-lg text-read2 text-18 ml-5">{text2}</p>
              </div>
              <div className="flex flex-col sm:mx-0 mx-2 md:w-4/6 lg:w-2/6 font-hairline sm:items-start">
                <div className="mb-6">
                  <img
                    src={icon3}
                    className="md:h-45 lg:h-64 sm:h-35 h-40 sm:mt-0"
                    alt={title3}
                  />
                </div>
                <h3 className="xl:text-xl font-sofia-med md:text-lg text-base2 font-bold md:mb-5 mb-3 w-48">
                  {title3}
                </h3>
                <p className="sm:text-lg text-read2 text-18 ml-5">{text3}</p>
              </div>
            </div>
          </Fade>
          <div className="flex justify-start md:mt-20 sm:mt-15 mt-0">
            <Link to="/services">
              <button className="bg-yellow text-black h-10 px-8 py-2 rounded-button leading-relaxed sm:mt-0 mt-15">
                Explore More
                <span className="arrow">
                  <span style={{ position: "absolute", right: "15px" }}>
                    {" "}
                    &nbsp;➞
                  </span>
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Trio
