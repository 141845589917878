import React from "react"
import Image from "gatsby-image"

const ProfileCard = ({ image, title, text }) => {
  return (
    <div className=" h-full flex flex-col sm:mx-0 mb-10 lg:p-12 md:p-10 p-12 mb-8 sm:mb-0" style={{ background: "#ECECEC", borderRadius: "15px" }}>
      <div className=""><Image fluid={image} alt="Profile picture " className="mx-auto lg:max-w-350 md:max-w-250 sm:max-w-190 max-w-290" /></div>
      <div className="flex flex-col text-center">
        <h4 className="font-sofia-med mt-5 mb-3 text-lg">
          {title}
        </h4>
        <p className="text-18xl text-read2">
          {text}
        </p>
      </div>
    </div>
  )
}

export default ProfileCard
