import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import Brands from "../components/Brands"
import Testimonial from "../components/Testimonial"
import ScrollCards from "../components/ScrollCards"
import AboutLower from "../components/AboutLower"
import Clients from "../components/Clients"
import Process from "../components/Process"
import "../styles/pageload.css"
import Careers from "../components/Careers"
import Trio from "../components/Trio"
import icon1 from "./../images/illustrations_sm-planning.svg"
import icon2 from "./../images/illustrations_sm-design.svg"
import icon3 from "./../images/illustrations_sm-development.svg"
import iconClients from "./../images/illustrations_lg-clients.svg"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const BlogIndex = ({ data }) => {
  const [animate, setAnimate] = useState(true)

  useEffect(() => {
    if (window.sessionStorage.getItem("firstLoadDone") === null) {
      setAnimate(true)
      window.sessionStorage.setItem("firstLoadDone", 1)
    } else {
      setAnimate(false)
    }
  })

  return (
    <div>
      {!animate ? null : (
        <div onLoad={() => console.log(this)} className="flip">
          <div className="black"></div>
          <div className="white"></div>
        </div>
      )}
      <Layout animating={animate}>
        <div className={`${animate ? "navigation" : ""}`}>
          <Hero />
        </div>
        <SEO title="Services | Cider Consulting" />
        <Brands />
        <div id="services">
          <Trio
            title1="Planning"
            title2="Design"
            title3="Development"
            text1={
              <div className="text-left trio" id="services">
                <ul
                  className="ml-4 lg:text-lg text-20"
                  style={{ listStyleType: "disc" }}
                >
                  <li>Product Strategy</li>
                  <li>Business Analysis</li>
                  <li>MVP Definition</li>
                  <li>Market &#038; User Research</li>
                </ul>
              </div>
            }
            text2={
              <div className="text-left trio lg:text-lg text-20">
                <ul style={{ listStyleType: "disc" }}>
                  <li>User Experience</li>
                  <li>Visual Design Analysis</li>
                  <li>Prototyping</li>
                </ul>
              </div>
            }
            text3={
              <div className="text-left trio lg:text-lg text-20">
                <ul style={{ listStyleType: "disc" }}>
                  <li>Cross-Platform</li>
                  <li>Native iOS &#038; Android</li>
                  <li>React Native</li>
                  <li>QA Automation</li>
                  <li>Web</li>
                  <li>E-Commerce</li>
                </ul>
              </div>
            }
            icon1={icon1}
            icon2={icon2}
            icon3={icon3}
          />
        </div>
        <Testimonial
          name="Chineze Uchechey"
          title="CEO at Amazon"
          gatsbyImage={data.profile.childImageSharp.fluid}
          text="Cider built the first MVP for us which allowed us to win
      numerous FinTech awards and subsequently we were able to acquire over
      4 million users into our apps."
        />
        <Process />
        <div id="clients" className="-mb-50">
          <Clients gatsbyImage={data.clients.childImageSharp.fluid} 
          image={iconClients}
          />
        </div>
        <div id="about">
          {/* <About /> */}
          <ScrollCards image={data.meetingImage.childImageSharp.fluid} />
          <AboutLower
            isaacImage={data.isaacImage.childImageSharp.fluid}
            rogelImage={data.rogelImage.childImageSharp.fluid}
          />
        </div>
        <Testimonial
          name="Deveedaas Nandi"
          title="CTO at Yahoo"
          gatsbyImage={data.deveedaas.childImageSharp.fluid}
          text="Cider built the first MVP for us which allowed us to win
      numerous FinTech awards and subsequently we were able to acquire over
      4 million users into our apps."
        />
        <div id="careers">
          <Careers
            title="We're Hiring!  Join us and do your life's best work at Cider"
            text="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
            link="/"
            button="See Open Roles"
            gatsbyImage={data.careers.childImageSharp.fluid}
          />
        </div>
      </Layout>
    </div>
  )
}

export default BlogIndex

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        description
      }
    }
    meetingImage: file(name: { eq: "meeting" }) {
      childImageSharp {
        fluid(maxWidth: 520) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    isaacImage: file(name: { eq: "isaac" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rogelImage: file(name: { eq: "rogel" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    profile: file(name: { eq: "profile_quote" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    deveedaas: file(name: { eq: "Deveedaas" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    careers: file(name: { eq: "careers" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Erin: file(name: { eq: "Erin" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    clients: file(name: { eq: "clients-img" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`